import { Workbox } from 'workbox-window'
import Signal from './signal'

/**
 * This controller abstracts access to Workbox functionalities.
 * The only Instance can be accessed by calling Instance static member.
 */
export default class WorkboxController {
    /**
     * The only available instance of WorkboxController class.
     */
    static readonly Instance: WorkboxController = new WorkboxController()

    readonly changeSignal = new Signal()

    private workbox: Workbox | undefined
    private serviceWorkerRegistration: ServiceWorkerRegistration | undefined
    private isWorkboxWaiting = false

    /**
     * A private contructor with forbid creating new instance with new keyword.
     *
     * @see Instance
     */
    private constructor() {}

    /**
     * Check the environment requirements for registering a service worker.
     * In most cases a service worker shouldn't be register in development environment.
     */
    canRegister() {
        return (
            process.env.NODE_ENV === 'production' &&
            'serviceWorker' in navigator
        )
    }

    /**
     * Register a service worker only if it matches the canRegister criteria.
     * This will check for new service worker update and set isWorkboxWaiting values.
     *
     * @see canRegister
     * @param {string} serviceWorkerFile path to the service worker file. example: service-worker.js
     */
    async register(serviceWorkerFile: string) {
        if (this.workbox === undefined && this.canRegister()) {
            this.workbox = new Workbox(serviceWorkerFile)
            this.workbox.addEventListener('waiting', () => this.setIsWorkboxWaiting(true))

            this.serviceWorkerRegistration = await this.workbox.register()

            this.setIsWorkboxWaiting(
                this.isWorkboxWaiting ||
                Boolean(this.serviceWorkerRegistration?.waiting),
            )

            if (!this.hasUpdate()) {
                setInterval(async () => {
                    console.log('Checking for update')
                    await this.serviceWorkerRegistration?.update()

                    this.setIsWorkboxWaiting(
                        this.isWorkboxWaiting ||
                        Boolean(this.serviceWorkerRegistration?.waiting),
                    )
                }, 1000 * 60 * 5)
            }
        }

        return this
    }

    /**
     * Determines if a new update is available for activation.
     */
    hasUpdate() {
        return this.isWorkboxWaiting
    }

    /**
     * trigger the skipWaiting on the installed service worker and reload the browser when it is activated.
     */
    doUpdate() {
        if (this.hasUpdate()) {
            this.workbox?.messageSW({type: 'SKIP_WAITING'})
            this.workbox?.addEventListener('controlling', () => window.location.reload())
        }
    }

    /**
     * Set the isWorkboxWaiting value and dispatch the change signal if it is required.
     *
     * @param {boolean} value the new value for isWorkboxWaiting
     */
    private setIsWorkboxWaiting(value: boolean) {
        if (this.isWorkboxWaiting !== value) {
            this.isWorkboxWaiting = value
            this.changeSignal.dispatch()
        }
    }
}
