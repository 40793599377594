import { InitializeFirebaseApp } from './app/utils/firebase-app'
import { Login, Logout } from './app/utils/keycloak-authentication'
import WorkboxController from './app/utils/workbox-controller'

/**
 * This iife will try to do keycloak authentication ONLY IF USER IS AUTHENTICATED:
 *   It will load the LaunchApp module and execute the default function.
 * @see LaunchApp
 */
(async () => {
    console.table(process.env)
    WorkboxController.Instance.register('/sw.js')
    InitializeFirebaseApp()

    try {
        const {authenticated: loginAuthenticated} = await Login()

        if (loginAuthenticated) {
            const launchAppModule = await import('./app/scripts/launch-app')
            launchAppModule.default()
        } else {
            console.log('This is unexpected but user is not authenticated')
            Logout()
        }
    } catch (e) {
        console.log(e.message)
    }
})()
