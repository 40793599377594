export const keycloak: any = createKeycloak()

/**
 * Create a new instance of Keycloak without running init function.
 */
function createKeycloak() {
    const KeycloakClass = (window as any).Keycloak
    return new KeycloakClass(process.env.REACT_APP_KEYCLOAK_CONFIG_URL)
}

/**
 * Initialize the Keycloak object and return the authentication result.
 * This will throw an error if the keycloak.js is not loaded before this function.
 */
export async function Login() {
    try {
        await keycloak.init({ onLoad: 'login-required', checkLoginIframe: false })
        return {keycloak, authenticated: true}
    } catch {
        return {keycloak, authenticated: false}
    }
}

/**
 * Check if the authentication tokien is expired and update it ONLY IF IT IS NEEDED.
 *
 * IT IS NEEDED WHEN:
 *   The token is expired OR it is going to be expired in less time than provided minValidity.
 *
 * @param minValidity in seconds (@default process.env.REACT_APP_KEYCLOAK_MIN_VALIDITY).
 */
export async function UpdateToken(
    minValidity = parseInt(process.env.REACT_APP_KEYCLOAK_MIN_VALIDITY),
) {
    try {
        await keycloak.updateToken(minValidity) // Min validitiy in seconds
        return {keycloak, authenticated: true}
    } catch {
        return {keycloak, authenticated: false}
    }
}

/**
 * Run the Logout function of the previously made keycloak object.
 */
export async function Logout() {
    keycloak.logout()
}

export interface TokenParsedData {
    email: string
    operatorId?: string
    realm_access: {
        roles: ('Admin' | 'Supplier')[]
    }
}

/**
 * Return the Parsed id token which contains the users information.
 * This will structured as defined in mapper configurations of Keycloak's clients.
 */
export function TokenParsed() {
    return keycloak.tokenParsed as TokenParsedData
}

/**
 * Return the Parsed id token which contains the users information.
 * This will structured as defined in mapper configurations of Keycloak's clients.
 */
export function IdTokenParsed() {
    return keycloak.idTokenParsed
}
